import { useContext } from 'react'
import { FlipperContext } from './FlipperProvider'

export const useExperiment = (experimentId: string) => {
  const { variants } = useContext(FlipperContext)

  const variant = variants[experimentId]

  const isControl = variant?.variantName === 'control' || !variant

  return {
    variant,
    isControl,
    isExperimentActive: !!variant,
  } as const
}
