import { useExperiment } from './useExperiment'
import { RESURFACE_SEARCH_EXPERIMENT_ID } from './Flipper.constants'
import { useFeatureToggle } from '@/modules/shared'

const useResurfaceSearchExperiment = () => {
  const { isEnabled } = useFeatureToggle()
  const isSearchEnabled = isEnabled('ResurfaceSearchExperiment')
  const { isControl } = useExperiment(RESURFACE_SEARCH_EXPERIMENT_ID)
  const isInResurfaceSearchExperiment = Boolean(!isControl && isSearchEnabled)

  return { isInResurfaceSearchExperiment }
}

export default useResurfaceSearchExperiment
