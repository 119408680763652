import { ComponentType, PropsWithChildren } from 'react'
import styles from './styles.scss'
import { UserProfile, UserAvatar } from '@/wwBrand/modules/core/services/auth'
import { onNavigationMenuHandle, onSubmenuHandle } from './headerFunctions'
import { navigationLinksData, navigationFooterLinksData, login, loginRoute } from './headerLinksData'
import Submenu from './submenu'
import { useRouter } from 'next/router'
import { ImageTKWW } from '@/ui/ImageWrapper/ImageTKWW'
import NextImage from 'next/image'
import { useHeaderPencilBanner } from '@/modules/core/contexts/HeaderPencilBanner/HeaderPencilBanner'
import { CART_ROUTE, CHECKOUT_ROUTE, CONFIRMATION_ROUTE, PRODUCT_LIST_ROUTE } from '@/routing/brandRoutes'
import { PencilBanner } from '@/ui/Banner/PencilBanner/PencilBanner'

const hostUrl = String(process.env.APP_PAPER_HOST)

export interface HeaderPropsWW {
  loggedIn: boolean
  userData?: UserProfile | null
}

const Header: ComponentType<PropsWithChildren<HeaderPropsWW>> = ({ loggedIn, userData }) => {
  const avatar = userData?.avatar as UserAvatar
  const letterName = userData?.username.charAt(0)
  const { asPath, basePath, pathname } = useRouter()
  const { bannerData } = useHeaderPencilBanner()
  const currentPath = String(basePath + asPath)
  const isPLP = pathname === PRODUCT_LIST_ROUTE
  const isCartOrCheckout =
    pathname === CART_ROUTE || (pathname.includes(CHECKOUT_ROUTE) && !pathname.includes(CONFIRMATION_ROUTE))

  let avatarIcon
  if (avatar?.url) {
    avatarIcon = <ImageTKWW alt={userData?.username} src={avatar?.url} layout="fill" />
  } else {
    avatarIcon = (
      <svg className="avatar__svg" viewBox="0 0 200 200" preserveAspectRatio="xMidYMin slice">
        <circle fill={avatar?.color} cx="100" cy="100" r="100"></circle>
        <text transform="translate(100,130)" y="0">
          <tspan fontSize="90" className="" fill="rgba(255,255,255,1)" textAnchor="middle">
            {letterName}
          </tspan>
        </text>
      </svg>
    )
  }
  const numMessages = userData?.unreadMessagesCount
  let messages
  numMessages === '0' ? (messages = false) : (messages = true)

  return (
    <>
      {isPLP && bannerData && <PencilBanner key={asPath} {...bannerData} />}
      <div className={styles.layoutHeader}>
        <span className={styles.layoutHeader__hamburger} onClick={onNavigationMenuHandle}>
          <svg viewBox="0 0 18 20" width="18" height="20">
            <path d="M14.17 15.42a.5.5 0 01.09.992l-.09.008H1a.5.5 0 01-.09-.992L1 15.42h13.17zm2.927-5.76a.5.5 0 01.09.991l-.09.008H1a.5.5 0 01-.09-.991L1 9.659h16.097zm0-5.66a.5.5 0 01.09.992l-.09.008H1a.5.5 0 01-.09-.992L1 4h16.097z"></path>
          </svg>
        </span>

        <a className={styles.layoutHeader__logoAnchor} href={hostUrl}>
          <NextImage
            src="https://www.weddingwire.com/assets/img/logos/gen_logoHeader.svg"
            alt="Weddings"
            width="194"
            height="29"
          />
        </a>
        <nav className={`${styles.layoutHeader__nav} app-header-menu`}>
          <ul className={styles.layoutNavMenu}>
            <li className={styles.layoutNavMenu__header}>
              <i className={styles.layoutNavMenu__itemClose} onClick={onNavigationMenuHandle}>
                <svg viewBox="0 0 26 26" width="26" height="26">
                  <path d="M12.983 10.862L23.405.439l2.122 2.122-10.423 10.422 10.423 10.422-2.122 2.122-10.422-10.423L2.561 25.527.439 23.405l10.423-10.422L.439 2.561 2.561.439l10.422 10.423z"></path>
                </svg>
              </i>
            </li>
            {navigationLinksData.map((link: any, index: number) => (
              <li
                className={`
              ${styles.layoutNavMenu__item} 
              ${link.mutableTablet ? styles['layoutNavMenu__item--mutableTablet'] : ''}
              ${link.mutableDesktop ? styles['layoutNavMenu__item--mutableDesktop'] : ''}
              `}
                key={index}
                onClick={onSubmenuHandle}
              >
                <a className={styles.layoutNavMenu__anchor} href={link.href} rel={link.rel} target={link.target}>
                  {link.content}
                </a>
                {link?.submenu && (
                  <>
                    <span className={styles.layoutNavMenu__anchorArrow}>
                      <svg viewBox="0 0 582 998">
                        <path d="M0 83c0 8.7 3.3 16.3 10 23l393 393L10 892c-6.7 6.7-10 14.3-10 23s3.3 16.3 10 23l50 50c6.7 6.7 14.3 10 23 10s16.3-3.3 23-10l466-466c6.7-6.7 10-14.3 10-23s-3.3-16.3-10-23L106 10C99.3 3.3 91.7 0 83 0S66.7 3.3 60 10L10 60C3.3 66.7 0 74.3 0 83z"></path>
                      </svg>
                    </span>
                    <Submenu title={link.content} submenu={link?.submenu} />
                  </>
                )}
              </li>
            ))}
            {navigationFooterLinksData.map((link: any, index: number) => (
              <li
                className={`
              ${styles.layoutNavMenu__itemFooter} 
              ${link.bordered ? styles['layoutNavMenu__itemFooter--bordered'] : ''}
              ${link.primary ? styles['layoutNavMenu__itemFooter--colored'] : ''}
              `}
                key={index}
              >
                <a className={styles.layoutNavMenu__anchorFooter} href={link.href} target={link.target}>
                  {link.content}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <div className={styles.layoutHeader__overlay} onClick={onNavigationMenuHandle}></div>
        <div className={styles.layoutHeader__authArea}>
          {!loggedIn && (
            <>
              <a
                href={`${loginRoute}?r=${currentPath}`}
                rel="nofollow"
                className={styles.layoutHeader__authNoLoggedAreaMobile}
              >
                <i className={styles.svgIcon}>
                  <svg viewBox="0 0 47 53">
                    <path d="M31.722 29.786C40.49 32.99 46.78 42.236 46.78 51.962v1H.846v-1c0-9.615 6.156-18.77 14.774-22.069-2.971-1.686-5.407-4.324-6.774-7.691-3.236-7.981.75-17.009 8.891-20.172 8.13-3.154 17.343.736 20.575 8.698 2.915 7.181-.03 15.22-6.59 19.058zm-12.16 1.698l-2.469.006c-7.922 2.633-13.8 10.765-14.222 19.472h41.885c-.423-8.744-6.343-16.9-14.309-19.501l-2.786.006a16.09 16.09 0 01-8.099.017zm-1.1-27.59c-7.11 2.762-10.576 10.617-7.763 17.556 2.82 6.944 10.88 10.347 17.998 7.582 7.107-2.761 10.575-10.62 7.762-17.551C33.64 4.537 25.579 1.133 18.46 3.895z"></path>
                  </svg>
                </i>
              </a>
              <div className={styles.layoutHeader__authNoLoggedArea}>
                <a className={styles.layoutHeader__vendorAuth} rel="nofollow" href={`${hostUrl}/vendors/home`}>
                  <i className={`${styles.svgIcon} ${styles.layoutHeader__vendorAuthIcon}`}>
                    <svg viewBox="0 0 48 41">
                      <path d="M44.3 27.917h.933V13.925c0-1.46-1.199-2.86-3.057-3.625H5.825c-1.859.766-3.058 2.164-3.058 3.625v13.992h14.866V24.39a1 1 0 011-1h10.734a1 1 0 011 1v3.527H44.3zm-2.133 2h-11.8v.51a1 1 0 01-1 1H18.633a1 1 0 01-1-1v-.51h-11.8v8.564h36.334v-8.564zM14.567 8.3v-.51c0-3.797 2.855-7.035 6.533-7.035h5.8c3.68 0 6.533 3.236 6.533 7.036V8.3h8.935a1 1 0 01.358.066c2.655 1.02 4.507 3.115 4.507 5.559v14.992a1 1 0 01-1 1h-2.066v9.564a1 1 0 01-1 1H4.833a1 1 0 01-1-1v-9.564H1.767a1 1 0 01-1-1V13.925c0-2.445 1.852-4.54 4.509-5.559a1 1 0 01.358-.066h8.933zm13.8 21.126V25.39h-8.734v4.036h8.734zm3.066-21.635c0-2.747-2.018-5.036-4.533-5.036h-5.8c-2.513 0-4.533 2.29-4.533 5.036V8.3h14.866v-.51z"></path>
                    </svg>
                  </i>
                  ARE YOU A VENDOR?
                </a>
                <ul className={styles.layoutNavMenuAuth}>
                  {login.map((link: any, index: number) => (
                    <li className={styles.layoutNavMenuAuth__item} key={index}>
                      <a
                        className={styles.layoutNavMenuAuth__anchor}
                        href={`${link.href}?${link.redirectPath}=${currentPath}`}
                      >
                        {link.content}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
          {loggedIn && (
            <ul className={styles.layoutNavMenuAuthLogged}>
              <li className={styles.layoutNavMenuAuthLogged__item}>
                <a className={styles.layoutNavMenuAuthLogged__itemAnchor} href={`${hostUrl}/users-saved.php`}>
                  <span className={styles.layoutNavMenuAuthLogged__itemFavoriteIcon}>
                    <svg viewBox="0 0 34 30" width="34" height="30">
                      <path d="M26.232.086C30.653.716 34 4.68 34 9.858c0 1.41-.371 2.884-1.073 4.412-1.35 2.937-3.878 6.013-7.247 9.134a68.921 68.921 0 01-5.582 4.625c-.665.496-1.284.941-1.84 1.328-.335.233-.577.396-.71.483a1 1 0 01-1.097 0c-.132-.087-.374-.25-.71-.483a67.429 67.429 0 01-1.84-1.328 68.921 68.921 0 01-5.58-4.625c-3.37-3.121-5.898-6.197-7.248-9.134C.371 12.742 0 11.268 0 9.858 0 4.681 3.347.716 7.768.086 11.6-.46 15.091 1.616 17 5.778 18.91 1.617 22.4-.46 26.232.086z"></path>
                    </svg>
                  </span>
                </a>
              </li>
              <li className={styles.layoutNavMenuAuthLogged__item}>
                <div className={styles.layoutNavMenuAuthLoggedInbox}>
                  <a className={styles.layoutNavMenuAuthLogged__itemAnchor} href={`${hostUrl}/users-mailbox.php`}>
                    <span className={styles.layoutNavMenuAuthLoggedInbox__icon}>
                      <svg viewBox="0 0 20 15" width="22" height="22">
                        <path d="M18.912 12.604V1.995c0-.47-.406-.911-.8-.911H1.888c-.393 0-.799.441-.799.911v10.609c0 .466.402.896.8.896h16.225c.397 0 .799-.43.799-.896zm1 0c0 1.003-.834 1.896-1.8 1.896H1.888c-.965 0-1.799-.893-1.799-1.896V1.995C.088.991.923.084 1.888.084h16.225c.964 0 1.799.907 1.799 1.911v10.609zm-9.631-4.71a.5.5 0 01-.562.827L2.513 3.828a.5.5 0 01.562-.827l7.206 4.893zm7.18 1.85a.5.5 0 01-.552.834l-3.29-2.175a.5.5 0 11.552-.834l3.29 2.175zm-14.37.834a.5.5 0 01-.552-.834l3.29-2.175a.5.5 0 11.551.834l-3.29 2.175zm7.185-1.859a.5.5 0 11-.562-.827l7.206-4.893a.5.5 0 01.562.827l-7.206 4.893z"></path>
                      </svg>
                    </span>
                    {messages && (
                      <span className={styles.layoutNavMenuAuthLoggedInbox__counter}>
                        <span className={styles.layoutNavMenuAuthLoggedInbox__number}>
                          {userData?.unreadMessagesCount}
                        </span>
                      </span>
                    )}
                  </a>
                </div>
              </li>
              <li className={styles.layoutNavMenuAuthLogged__avatar}>
                <div className={styles.layoutNavMenuAuthLogged__avatarContainer}>{avatarIcon}</div>
              </li>
            </ul>
          )}
        </div>
      </div>
      {isCartOrCheckout && bannerData && <PencilBanner {...bannerData} />}
    </>
  )
}

export default Header
